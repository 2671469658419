import React, { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { ContactReason, PortalAccessRequest } from '../../api/data-contracts';
import { submitAccessRequest } from '../../api/AccessRequest';
import { extractErrorMessage } from '../../utils/error';
import { toast } from "sonner";

interface RequestAccessDialogProps {
  organizationId: string;
  open: boolean;
  onOpenChange(open: boolean): void;
}

export const RequestAccessDialog = ({
  organizationId,
  open,
  onOpenChange,
}: RequestAccessDialogProps) => {
  const [contactName, setContactName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [reason, setReason] = useState<ContactReason>();
  const [position, setPosition] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAccessRequest = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const accessRequest: PortalAccessRequest = {
      contact_name: contactName,
      company_name: companyName,
      email_address: emailAddress,
      reason: reason,
      position: position,
      organization_id: organizationId
    };

    try {
      await submitAccessRequest(accessRequest);
      onOpenChange(false);
      toast.success("Access requested. We'll email you soon.");
    } catch (error) {
      toast.error("Couldn't request access: " + extractErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader className="space-y-4">
          <DialogTitle>Request access to all documents</DialogTitle>
          <DialogDescription>
            Fill out this form to request access to all documents. We'll review your request and get back to you shortly.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleAccessRequest} className="space-y-6 mt-2">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="contactName" className="flex items-center">
                Contact name
                <span className="text-red-500 ml-1">*</span>
              </Label>
              <Input
                id="contactName"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                placeholder="Jane Doe"
                required
                className="h-10"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="companyName" className="flex items-center">
                Company name
                <span className="text-red-500 ml-1">*</span>
              </Label>
              <Input
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Acme Inc."
                required
                className="h-10"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="emailAddress" className="flex items-center">
                Email address
                <span className="text-red-500 ml-1">*</span>
              </Label>
              <Input
                id="emailAddress"
                type="email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                placeholder="security@acme.com"
                required
                className="h-10"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="position" className="flex items-center">
                Role
                <span className="text-gray-400 ml-1 text-sm">(optional)</span>
              </Label>
              <Input
                id="position"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                placeholder="Head of Security"
                className="h-10"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="reason" className="flex items-center">
                Reason
                <span className="text-red-500 ml-1">*</span>
              </Label>
              <Select onValueChange={(value) => setReason(value as ContactReason)} required>
                <SelectTrigger className="h-10">
                  <SelectValue placeholder="Select a reason" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="EXISTING_CUSTOMER">I'm an existing customer</SelectItem>
                  <SelectItem value="PROSPECTIVE_CUSTOMER">I'm interested in purchasing this product</SelectItem>
                  <SelectItem value="OTHER">Other</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <DialogFooter className="mt-6">
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)} className="h-10">
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading} className="h-10">
              {isLoading ? "Requesting..." : "Request access"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};