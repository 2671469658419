import { styled } from '../../stitches.config';

export const Row = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  defaultVariants: {
    spacing: 'none',
  },
  variants: {
    alignVertical: {
      top: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      bottom: {
        alignItems: 'flex-end',
      },
    },
    collapseBelow: {
      smallDevices: {
        '@smallDevices': {
          flexDirection: 'column',
        },
      },
    },
    spacing: {
      none: {},
      xsmall: { gap: '$xsmall' },
      small: { gap: '$small' },
      medium: { gap: '$large' },
      large: { gap: '$large' },
      xlarge: { gap: '$xlarge' },
      xxlarge: { gap: '$xxxlarge' },
      xxxlarge: { gap: '$xxxxxlarge' },
      between: {
        gap: '$small',
        justifyContent: 'space-between',
      },
      end: {
        gap: '$small',
        justifyContent: 'flex-end',
      }
    },
  },
  compoundVariants: [
    {
      alignVertical: 'top',
      collapseBelow: 'smallDevices',
      css: {
        '@smallDevices': {
          alignItems: 'flex-start',
        },
      },
    },
    {
      alignVertical: 'center',
      collapseBelow: 'smallDevices',
      css: {
        '@smallDevices': {
          alignItems: 'flex-start',
        },
      },
    },
    {
      alignVertical: 'bottom',
      collapseBelow: 'smallDevices',
      css: {
        '@smallDevices': {
          alignItems: 'flex-start',
        },
      },
    },
  ],
});
