import { styled } from '../../stitches.config';

export const Card = styled('div', {
  backgroundColor: '$white',
  borderRadius: '$default',
  border: '1px solid $silvermist-100',
  overflow: 'hidden',
  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
      },
    },
  },
});

export const CardPadding = styled('div', {
  padding: '$large',
});

export const CardHeading = styled('div', {
  padding: '$medium $large',
  background: '$silvermist-50',
  borderBottom: '1px solid $silvermist-100',
});
