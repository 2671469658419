import { CompanyContent, SubProcessor } from '../../api/data-contracts';
import { Text } from '../typography/Text';
import { Flex, Table, Tooltip } from '@radix-ui/themes';
import React from 'react';
import { styled } from '../../stitches.config';
import Image from 'next/image';
import { Badge } from '../layout/Badge';

const standardBadgeColors = {
  ISO42001: "pineglade",
  NIST_AI: "pineglade",
  SOC2: "pineglade",
  CCPA: "lupine",
  ISO27001: "silvermist",
  OWASP: "bloodmoon",
  GDPR: "lupine",
  HIPAA: "lupine",
  FedRAMP: "sunbark",
  PCIDSS: "sunbark",
};

export default function TrustCenterSubProcessor({ companyContent }: { companyContent: CompanyContent }) {
  return (
    <SubProcessorTableContainer>
      <Text as="h1" size="large" weight="medium" color="primary">
        Sub-Processors
      </Text>
      <SubProcessorTable variant="surface" size="1">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>{renderResourceHeader('Name')}</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>{renderResourceHeader('Purpose')}</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>{renderResourceHeader('Compliance')}</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>{renderResourceHeader('Location')}</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {companyContent.sub_processors.map((subProcessor) => renderSubProcessor(subProcessor))}
        </Table.Body>
      </SubProcessorTable>
    </SubProcessorTableContainer>
  );
}

function renderResourceHeader(title: string) {
  return (
    <Flex direction="column" gap="3" style={{ padding: '1rem' }}>
      <Text as="p" size="medium" color="secondary" weight="semibold">
        {title}
      </Text>
    </Flex>
  );
}

function renderSubProcessor(subProcessor: SubProcessor) {
  return (
    <Table.Row key={subProcessor.name} align="center">
      <Table.Cell>
        {
          <Flex direction="column" gap="3" align="center" style={{ padding: '1rem' }}>
            <Tooltip content={subProcessor.name}>
              <Image
                src={subProcessor.logo.url}
                alt={subProcessor.logo.alt}
                width={subProcessor.logo.width}
                height={subProcessor.logo.height}
                priority={true}
              />
            </Tooltip>
          </Flex>
        }
      </Table.Cell>
      <Table.Cell>
        {
          <Flex direction="column" gap="3" style={{ padding: '1rem' }}>
            <Text as="p" size="medium" color="secondary">
              {subProcessor.purpose}
            </Text>
          </Flex>
        }
      </Table.Cell>
      <Table.Cell>
        {
          <Flex direction="row" gap="3" style={{ padding: '1rem' }}>
            {subProcessor.standards.slice(0, 5).map(standard => (
              <Badge
                key={standard}
                variant="fill"
                color={standardBadgeColors[standard.replace('-', '')] || "defaultColor"}
              >
                {standard}
              </Badge>
            ))}
          </Flex>
        }
      </Table.Cell>
      <Table.Cell>
        {
          <Flex direction="column" gap="3" style={{ padding: '1rem' }}>
            <Text as="p" size="medium" color="secondary">
              {subProcessor.location.join(', ')}
            </Text>
          </Flex>
        }
      </Table.Cell>
    </Table.Row>
  );
}


const SubProcessorTableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingBlock: '$large'
});

const SubProcessorTable = styled(Table.Root, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: '$large'
});