import React from 'react';
import TrustCenterPage from '../components/trust/TrustCenterPage';
import LandingPage from '../components/marketing/LandingPage';
import { getAllBlogPostSummaries, PostSummary } from '../components/blog/utils';


interface Props {
  latestPost: PostSummary;
  subdomain: string;
}

export async function getServerSideProps(context: { req: { headers: { host: string } } }) {
  const { req } = context;
  const host = req.headers.host;
  const subdomain = host.split('.')[0];
  const latestPost = getAllBlogPostSummaries()[0];

  return {
    props: {
      subdomain,
      latestPost,
    },
  };
}



const MainPage = ({ subdomain, latestPost }: Props) => {
  const supportedTrustCenterSubdomains = ['juicebox', 'fini', 'mendable', 'lancey', 'solveintelligence', 'greenlite', 'asklio'];
  if (supportedTrustCenterSubdomains.includes(subdomain)) {
    return <TrustCenterPage companyName={subdomain} />;
  }
  return <LandingPage latestPost={latestPost} />;
};

export default MainPage;
