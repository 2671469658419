import { CompanyContent, ResourceModel } from '../../api/data-contracts';
import { Text } from '../typography/Text';
import { Flex, Table } from '@radix-ui/themes';
import React, { useState } from 'react';
import { styled } from '../../stitches.config';
import { Button } from '../inputs/Button';
import { ArrowDownTrayIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default function TrustCenterResource({ companyContent, setOpenRequestAccessDialog, isDownloadable }: {
  companyContent: CompanyContent,
  setOpenRequestAccessDialog: (bool) => void,
  isDownloadable: boolean
}) {

  const [isDownloading, setIsDownloading] = useState(false);

  const handleBulkDownload = async () => {
    setIsDownloading(true);
    const zip = new JSZip();
    const downloadsFolder = zip.folder("downloads");
    const resources = companyContent.resources.filter(resource => (isDownloadable || resource.is_public) && resource.url);
    const downloadPromises = resources.map(async (resource) => {
      try {
        const response = await fetch(resource.url);
        if (response.status === 200) {
          const blob = await response.blob();
          downloadsFolder.file(resource.title.replace(/\s+/g, '_') + '.pdf', blob);
        } else {
          throw new Error('Resource download failed');
        }
      } catch (error) {
        console.error(`Failed to download ${resource.title}:`, error);
      }
    });

    await Promise.all(downloadPromises);
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "resources.zip");
      setIsDownloading(false);
    }).catch(() => {
      setIsDownloading(false);
    });
  };

  return (
    <ResourcesTableContainer>
      <Flex direction="row" justify="between" align="center">
        <Text as="h1" size="large" weight="medium" color="primary">
          Resources
        </Text>
        {isDownloadable && (
          <Button as="button" icon={ArrowDownTrayIcon} onClick={handleBulkDownload} loading={isDownloading}>
            {
              isDownloading ? 'Downloading' : 'Download all'
            }
          </Button>
        )}
      </Flex>
      <ResourcesTable variant="surface" size="1">
        <Table.Body>
          {companyContent.resources.map((resource) => renderResource(resource, setOpenRequestAccessDialog, isDownloadable))}
        </Table.Body>
      </ResourcesTable>
    </ResourcesTableContainer>
  );
}

function renderResource(resource: ResourceModel, setOpenRequestAccessDialog: (bool) => void, isDownloadable: boolean) {
  return (
    <Table.Row key={resource.title} align="center">
      <Table.Cell>
        {
          <Flex direction="column" gap="3" style={{ padding: '1rem' }}>
            <Text as="p" size="medium" weight="semibold">
              {resource.title}
            </Text>
          </Flex>
        }
      </Table.Cell>
      <Table.Cell>
        {
          <Flex direction="column" gap="3" style={{ padding: '1rem' }}>
            <Text as="p" size="medium" color="secondary">
              {resource.description}
            </Text>
          </Flex>
        }
      </Table.Cell>
      <Table.Cell>
        {
          <Flex direction="column" gap="3" style={{ padding: '1rem' }}>
            {(isDownloadable || resource.is_public) ? (
              <Button as="button" variant="secondary" icon={ArrowDownTrayIcon} onClick={() => window.open(resource.url)}>
                Download
              </Button>
            ) : (
              <Button as="button" variant="secondary" icon={LockClosedIcon} onClick={() => setOpenRequestAccessDialog(true)}>
                Download
              </Button>
            )}
          </Flex>
        }
      </Table.Cell>
    </Table.Row>
  );
}


const ResourcesTableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingBlock: '$large'
});

const ResourcesTable = styled(Table.Root, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginTop: '$large'
});