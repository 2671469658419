import { keyframes, styled } from '../../stitches.config';
import { Text } from '../typography/Text';

const rotate = keyframes({
  to: { transform: 'rotate(360deg)' },
});

const BadgeContainer = styled('div', {
  display: 'inline-block',
  padding: '$xsmall',
  borderRadius: '$round',
  color: '$white',
  transition: 'background $medium ease, outline-offset $fast ease',
  outlineOffset: 0,
  overflow: 'hidden',
  defaultVariants: {
    variant: 'neutral',
  },
  variants: {
    color: {
      silvermist: {
        $$background: '$colors$silvermist-50',
        '$$background-hover': '$colors$silvermist-100',
        $$border: '$colors$silvermist-100',
        '$$border-loading': '$colors$silvermist-300',
        color: '$silvermist-700',
      },
      lupine: {
        $$background: '$colors$lupine-50',
        '$$background-hover': '$colors$lupine-100',
        $$border: '$colors$lupine-100',
        '$$border-loading': '$colors$lupine-300',
        color: '$lupine-700',
      },
      pineglade: {
        $$background: '$colors$pineglade-50',
        '$$background-hover': '$colors$pineglade-100',
        $$border: '$colors$pineglade-100',
        '$$border-loading': '$colors$pineglade-300',
        color: '$pineglade-700',
      },
      bloodmoon: {
        $$background: '$colors$bloodmoon-50',
        '$$background-hover': '$colors$bloodmoon-100',
        $$border: '$colors$bloodmoon-100',
        '$$border-loading': '$colors$bloodmoon-300',
        color: '$bloodmoon-700',
      },
      sunbark: {
        $$background: '$colors$sunbark-50',
        '$$background-hover': '$colors$sunbark-100',
        $$border: '$colors$sunbark-100',
        '$$border-loading': '$colors$sunbark-300',
        color: '$sunbark-700',
      },
    },
    variant: {
      fill: {
        backgroundColor: '$$background',
        'a&:hover': {
          backgroundColor: '$$background-hover',
        },
        'a&:focus-visible': {
          outlineColor: 'currentColor',
          outlineOffset: 2,
          outlineWidth: 2,
        },
      },
      outline: {
        background: '$white',
        boxShadow: 'inset 0 0 0 1px $$border',
      },
      bordered: {
        backgroundColor: '$$background',
        boxShadow: 'inset 0 0 0 2px $$border',
      },
    },
    loading: {
      true: {
        position: 'relative',
        boxShadow: 'none',
        zIndex: 0,
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          height: 0,
          paddingBottom: '100%',
          marginTop: '-50%',
          marginLeft: '-50%',
          background:
            'conic-gradient(from 180deg at 50% 50%, $$border-loading 0deg, $$border 360deg);',
          zIndex: -1,
          animation: `${rotate} 3s linear infinite`,
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          inset: 1,
          background: '$white',
          borderRadius: '$round',
          zIndex: -1,
        },
      },
    },
  },
});

export interface BadgeProps {
  as?: 'div' | 'a';
  href?: string;
  target?: '_blank';
  children: React.ReactNode;
  color: React.ComponentProps<typeof BadgeContainer>['color'];
  variant: React.ComponentProps<typeof BadgeContainer>['variant'];
  loading?: boolean;
}

export const Badge = ({
  as = 'div',
  children,
  color,
  href,
  target,
  variant,
  loading,
}: BadgeProps) => {
  return (
    <BadgeContainer
      variant={variant}
      color={color}
      as={as}
      href={href}
      target={target}
      loading={loading}
    >
      <Text weight="medium" color="inherit" size="xsmall">
        {children}
      </Text>
    </BadgeContainer>
  );
};