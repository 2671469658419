import React from 'react';

export const VerticalLine = ({ theme }) => {
  const color = theme === 'light' ? '#000000' : '#FFFFFF';
  return (
    <svg width="4" height="42" viewBox="0 0 4 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient" x1="1" y1="1" x2="1" y2="41" gradientUnits="userSpaceOnUse">
          <stop stopColor='transparent' stopOpacity="0"/>
          <stop offset="0.5" stopColor={color} stopOpacity="1"/>
          <stop offset="1" stopColor='transparent' stopOpacity="0"/>
        </linearGradient>
      </defs>
      <path d="M1 1L1 41" stroke="url(#gradient)" strokeLinecap="round"/>
    </svg>
  );
}
