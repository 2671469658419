import { styled } from '../../stitches.config';
import { Flex, Tabs } from '@radix-ui/themes';
import React, { useEffect, useState } from 'react';
import { Text } from '../typography/Text';
import { WolfiaLogo } from '../logo/WolfiaLogo';
import TrustCenterOverview from './overview';
import TrustCenterControls from './controls';
import { useRouter } from 'next/router';
import { Meta } from '../Meta';
import { useGetTrustCenterContent } from '../../api/TrustCenterContent';
import { FullPageLoading } from '../feedback/FullPageLoading';
import { CompanyContent } from '../../api/data-contracts';
import { capitalizeFirstLetter } from '../../utils/string';
import { RequestAccessDialog } from './RequestAccessDialog';
import TrustCenterSubProcessor from './subprocessor';
import TrustCenterResource from './resources';
import TrustCenterISOControls from './iso_controls';
import { useSearchParams } from 'next/navigation';
import { TrustCenterHeader } from './TrustCenterHeader';

const MainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: '100vh',
  overflow: 'visible',
  marginLeft: 'auto',
  marginRight: 'auto'
});

const TabsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '$xxxlarge',
  '@smallDevices': {
    padding: 0
  }
});

const Footer = styled('footer', {
  position: 'relative',
  width: '100%',
  paddingBottom: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 'auto'
});


function TrustCenterTabs({ companyContent, setOpenRequestAccessDialog }: {
  companyContent: CompanyContent,
  setOpenRequestAccessDialog: (bool) => void
}) {
  const router = useRouter();

  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const tab = router.query.tab as string || 'overview';
    setActiveTab(tab);
  }, [router.query.tab]);

  const handleTabChange = (newTab) => {
    router.replace({
      pathname: router.pathname,
      query: { ...router.query, tab: newTab }
    }, null, { shallow: true }); // shallow prevents full page reload
    setActiveTab(newTab);
  };

  const tabs = [
    {
      value: 'overview',
      label: 'Overview',
      render: true,
      content: <TrustCenterOverview companyContent={companyContent} />
    },
    {
      value: 'resource',
      label: 'Resources',
      render: companyContent.resources.length > 0,
      content: <TrustCenterResource companyContent={companyContent}
                                    setOpenRequestAccessDialog={setOpenRequestAccessDialog}
                                    isDownloadable={companyContent.is_access_enabled} />
    },
    {
      value: 'subprocessor',
      label: 'Sub-Processors',
      render: !!companyContent.sub_processors,
      content: <TrustCenterSubProcessor companyContent={companyContent} />
    },
    {
      value: 'controls',
      label: 'Controls',
      render: true,
      content: <TrustCenterControls companyContent={companyContent} />
    },
    {
      value: 'iso_controls',
      label: 'ISO 42001',
      render: !!companyContent.iso_controls,
      content: <TrustCenterISOControls companyContent={companyContent} />
    }
  ];

  return (
    <TabsContainer>
      <Tabs.Root value={activeTab} defaultValue="overview" onValueChange={handleTabChange}>
        <Tabs.List style={{ justifyContent: 'center', display: 'flex' }}>
          {tabs.filter(tab => tab.render).map(tab => (
            <Tabs.Trigger value={tab.value} key={tab.value}>{tab.label}</Tabs.Trigger>
          ))}
        </Tabs.List>
        {tabs.filter(tab => tab.render).map(tab => (
          <Tabs.Content value={tab.value} key={tab.value} style={{ paddingTop: '2rem' }}>
            {tab.content}
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </TabsContainer>
  );
}

function TrustCenterFooter() {
  return <Footer>
    <Flex direction="row" gap="3" align="center">
      <Text as="p" size="small" color="secondary">
        AI Trust Center is powered by
      </Text>
      <WolfiaLogo height={30} width={30} />
    </Flex>
  </Footer>;
}

export default function TrustCenterPage({ companyName }: { companyName: string }) {
  const params = useSearchParams();
  const token = params.get('token');
  const { data: companyContent, isLoading, error } = useGetTrustCenterContent({
    company_name: companyName,
    token: token
  });
  const [openRequestAccessDialog, setOpenRequestAccessDialog] = useState(false);

  const title = companyContent?.company_name ? `${capitalizeFirstLetter(companyContent.company_name)} - AI Trust Center` : 'AI Trust Center';

  if (isLoading || error || !companyContent) {
    return <FullPageLoading error={error} errorMessage="Unable to load trust center." />;
  }

  return (
    <>
      <Meta title={title} favicon={companyContent.company_fav_icon} />
      <MainContainer>
        <TrustCenterHeader
          companyContent={companyContent}
          setOpenRequestAccessDialog={setOpenRequestAccessDialog}
        />
        <TrustCenterTabs
          companyContent={companyContent}
          setOpenRequestAccessDialog={setOpenRequestAccessDialog}
        />
        <TrustCenterFooter />
        <RequestAccessDialog
          organizationId={companyContent.organization_id}
          open={openRequestAccessDialog}
          onOpenChange={setOpenRequestAccessDialog}
        />
      </MainContainer>
    </>
  );
}
