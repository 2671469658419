/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosError } from 'axios';
import useSWR, { SWRConfiguration } from 'swr';
import { CompanyContent } from './data-contracts';

/**
 * Get all content for the trust center for a specific company.
 */
export const useGetTrustCenterContent = (
  query: {
    /**
     * Company Name
     */
    company_name: string;
    /**
     * Token
     */
    token?: string;
  },
  config?: SWRConfiguration<CompanyContent>
) => {
  const queryString = new URLSearchParams(query).toString();
  const queryParameters = queryString ? `?${queryString}` : '';
  return useSWR<CompanyContent, AxiosError>(
    `/trust-center-content${queryParameters}`,
    config
  );
};
