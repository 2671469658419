import { styled } from '../../stitches.config';
import { darkTheme } from '../../stitches.config';
import { CompanyContent, Theme } from '../../api/data-contracts';
import { Flex } from '@radix-ui/themes';
import Image from 'next/image';
import { Text } from '../typography/Text';
import { ButtonGroup } from '../inputs/ButtonGroup';
import { Button } from '../inputs/Button';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { VerticalLine } from '../utils/VerticalLine';

const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '2.5rem 0',
  background: 'linear-gradient(to bottom, var(--gradient-start, #F4F2FF), var(--gradient-end, #F9FFFD))',
  borderBottom: '1px solid $silvermist-100'
})

const LinksContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$xsmall',
  '@smallDevices': {
    gap: '$xxsmall',
    flexDirection: 'column',
    alignItems: 'center'
  },
  'a': {
    textDecoration: 'none',
    color: 'var(--link-color, #1a0dab)',
    '&.dark-theme': {
      color: '#8ab4f8'
    }
  }
});

const LinksSeparator = styled('div', {
  color: '$silvermist-500',
  '@smallDevices': {
    display: 'none'
  }
})


const CustomerLogosContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$large',
  '@smallDevices': {
    flexDirection: 'column',
    alignItems: 'center'
  }
})

export function TrustCenterHeader({ companyContent, setOpenRequestAccessDialog }:
                                    {
                                      companyContent: CompanyContent,
                                      setOpenRequestAccessDialog: (bool) => void
                                    }) {

  const theme = companyContent.header.appearance.theme == Theme.Dark ? darkTheme : undefined
  const isDarkTheme = companyContent.header.appearance.theme === Theme.Dark;

  return (<HeaderContainer style={{
      '--gradient-start': companyContent.header.appearance.gradient_start,
      '--gradient-end': companyContent.header.appearance.gradient_end
    }} className={theme}>
      <Flex direction="column" align="center" justify="center" gap="5">
        <Flex direction="row" align="center" gap="3">
          <Image
            src={companyContent.header.company_logo.url}
            alt={companyContent.header.company_logo.alt}
            width={companyContent.header.company_logo.width}
            height={companyContent.header.company_logo.height}
            priority={true}
          />
          <VerticalLine theme={companyContent.header.appearance.theme}/>
          <Text as="h2" size="large" weight="medium" align="center">
            AI Trust Center
          </Text>
        </Flex>
        {
          !companyContent.is_access_enabled && companyContent.resources.length > 0 &&
          <ButtonGroup>
            <Button as="button" variant="primary" icon={LockClosedIcon}
                    onClick={() => setOpenRequestAccessDialog(true)}>
              Request access
            </Button>
          </ButtonGroup>
        }
        <Flex direction="column" gap="5" align="center"
              style={{
                maxWidth: '1200px',
                paddingInline: '24px',
                marginInline: 'auto',
                width: '100%'
              }}>
          <Text as="p" size="medium" color="secondary" align="center" style={{ maxWidth: 800 }}>
            {companyContent.header.company_description}
          </Text>
          <LinksContainer style={{ '--link-color': isDarkTheme ? '#8ab4f8' : '#1a0dab' }}>
            <a href={companyContent.header.terms_of_service_url} className={isDarkTheme ? 'dark-theme' : ''}>Terms of Service</a>
            <LinksSeparator>•</LinksSeparator>
            <a href={companyContent.header.privacy_url} className={isDarkTheme ? 'dark-theme' : ''}>Privacy Policy</a>
            <LinksSeparator>•</LinksSeparator>
            <a href={'mailto:' + companyContent.header.security_email} className={isDarkTheme ? 'dark-theme' : ''}>{companyContent.header.security_email}</a>
          </LinksContainer>
          {
            companyContent.header.customer_logo_urls && (
              <>
                <Text as="p" size="medium" color="secondary" weight="medium">
                  Trusted by
                </Text>
                <CustomerLogosContainer>
                  {companyContent.header.customer_logo_urls.map((logo, index) => (
                    <Image
                      key={index}
                      src={logo.url}
                      alt={logo.alt}
                      width={logo.width}
                      height={logo.height}
                      priority={true}
                    />
                  ))}
                </CustomerLogosContainer>
              </>
            )
          }
          {
            companyContent.header.compliance && (
              <>
                <Text as="p" size="medium" color="secondary" weight="medium">
                  Compliant with
                </Text>
                <Flex direction="row" gap="7">
                  {companyContent.header.compliance.map((logo, index) => (
                    <Image
                      key={index}
                      src={logo.url}
                      alt={logo.alt}
                      width={logo.width}
                      height={logo.height}
                      priority={true}
                    />
                  ))}
                </Flex>
              </>
            )
          }
        </Flex>
      </Flex>
    </HeaderContainer>
  )
}
