import {Flex} from '@radix-ui/themes';
import {Text} from '../typography/Text';
import {Card, CardPadding} from '../layout/Card';
import Image from 'next/image';
import {CheckCircleIcon, ChevronRightIcon, XCircleIcon} from '@heroicons/react/24/solid';
import React from 'react';
import {styled} from '../../stitches.config';
import {useRouter} from 'next/router';
import {CompanyContent} from '../../api/data-contracts';
import {Row} from "../layout/Row";
import {WolfiaLogoIcon} from "../logo/WolfiaLogoIcon";

const MainGridContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '$xlarge',
  padding: '$large',
  '@smallDevices': {
    gridTemplateColumns: '1fr',
    gap: '$medium',
    padding: '$small',
  },
})

const TitlePadding = styled('div', {
  paddingTop: '$medium',
  paddingLeft: 'calc($large + $large)',
  '@smallDevices': {
    paddingTop: '$small',
    paddingLeft: 'calc($small + $large)',
  },
})

const ModelsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '$medium',
})

const ModelsLogosContainer = styled('div', {
  display: 'flex',
  width: 'auto',
  justifyContent: 'center',
  gap: '$xlarge',
  gridTemplateColumns: 'repeat(4, 1fr)',
  padding: '$large',
  '@smallDevices': {
    padding: '$small',
  },
})

const DataUseContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

const ControlsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

const DocumentsContainer = styled('div', {
  display: 'none', // 'flex', // HIDING THIS SECTION FOR NOW
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingTop: '$large',
})

export const GreenCheckCircleIcon = styled(CheckCircleIcon, {
  color: '$pineglade-500'
})

export const GrayXCircleIcon = styled(XCircleIcon, {
  color: '$silvermist-300'
})

function ControlCard({title, children, onClick}) {
  const limit = 4
  const childArray = React.Children.toArray(children)
  const isExceedingDisplayLimit = childArray.length > limit
  const childrenToShow = isExceedingDisplayLimit ? childArray.slice(0, limit) : childArray

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick()
      event.preventDefault()
    }
  }

  return (
    <HoverCard
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <CardPadding>
        <Flex direction="column" gap="3">
          <Flex justify="between">
            <Text as="h3" size="medium" color="primary" weight="semibold">
              {title}
            </Text>
            <ChevronRightIcon width="1rem" height="1rem"/>
          </Flex>
          {childrenToShow}
          {isExceedingDisplayLimit && (
            <Text as="p" size="medium" color="link">
              +{childArray.length - limit} more
            </Text>
          )}
        </Flex>
      </CardPadding>
    </HoverCard>
  )
}

function ControlCardItem({control, status}) {
  return (
    <Flex direction="row" gap="3" align="center" style={{alignItems: 'center'}}>
      <div style={{flexShrink: 0}}>
        {status ? <GreenCheckCircleIcon width="20" height="20"/> : <GrayXCircleIcon width="20" height="20"/>}
      </div>
      <Text as="p" size="medium" color="secondary" style={{minWidth: 0}}>
        {control}
      </Text>
    </Flex>
  )
}


function UsageItem({use, check: check}) {
  return (
    <Flex direction="row" gap="3" align="center" style={{alignItems: 'center'}}>
      <div style={{flexShrink: 0}}>
        {check == true ? <GreenCheckCircleIcon width="20" height="20"/> : <GrayXCircleIcon width="20" height="20"/>}
      </div>
      <Text as="p" size="medium" color="secondary">
        {use}
      </Text>
    </Flex>
  )
}

export default function TrustCenterOverview({companyContent}: { companyContent: CompanyContent }) {
  const router = useRouter()

  const navigateToControl = (category) => {
    router.push(`/?tab=controls#${category.toLowerCase().replace(/\s+/g, '-')}`, undefined, {shallow: true})
  }

  return <Flex direction="row" gap="4">
    <Flex direction="column" gap="4" style={{maxWidth: 1200}}>
      <ModelsContainer>
        <TitlePadding>
          <Text as="p" size="large" color="primary" weight="medium">
            Models
          </Text>
        </TitlePadding>
        <MainGridContainer>
          <Card>
            <CardPadding>
              <Flex direction="column" gap="3">
                <Text as="h3" size="small" color="accent" weight="medium">
                  Architecture
                </Text>
                <Text as="h2" size="xlarge" weight="medium">
                  {companyContent.ai_model_architecture.title}
                </Text>
                <Text as="p" size="medium" color="secondary">
                  {companyContent.ai_model_architecture.summary}
                </Text>
                {companyContent.ai_model_architecture.items.map((item) => (
                  <UsageItem
                    use={item.description}
                    check={item.is_supported}
                  />
                ))}
                <ModelsLogosContainer>
                  {companyContent.ai_model_architecture.logos.map((logo) => (
                    <Image
                      src={logo.url}
                      alt={logo.alt}
                      width={logo.width}
                      height={logo.height}
                      priority={true}
                    />
                  ))}
                </ModelsLogosContainer>
              </Flex>
            </CardPadding>
          </Card>
          <Card>
            <CardPadding>
              <Flex direction="column" gap="3">
                <Text as="h3" size="small" color="accent" weight="medium">
                  Usage
                </Text>
                <Text as="h2" size="xlarge" weight="medium">
                  {companyContent.ai_model_use.title}
                </Text>
                <Text as="p" size="medium" color="secondary">
                  {companyContent.ai_model_use.summary}
                </Text>
                {companyContent.ai_model_use.items.map((item) => (
                  <UsageItem
                    use={item.description}
                    check={item.is_supported}
                  />
                ))}
              </Flex>
            </CardPadding>
          </Card>
        </MainGridContainer>
      </ModelsContainer>
      <DataUseContainer>
        <TitlePadding>
          <Text as="p" size="large" color="primary" weight="medium">
            Data use
          </Text>
        </TitlePadding>
        <MainGridContainer>
          <Card>
            <CardPadding>
              <Flex direction="column" gap="3">
                <Text as="h3" size="medium" color="primary" weight="semibold">
                  Training
                </Text>
                <Text as="p" size="medium" color="secondary">
                  {companyContent.data_use_training.summary}
                </Text>
                {companyContent.data_use_training.items.map((item) => (
                  <UsageItem
                    use={item.description}
                    check={item.is_supported}
                  />
                ))}
                {
                  companyContent.data_use_training.is_verified &&
                    <Row spacing="xsmall" alignVertical="center" style={{marginTop: '1rem'}}>
                        <WolfiaLogoIcon width="36" height="36"/>
                        <Text as="p" size="medium" weight="semibold">
                            Audited and verified by Wolfia
                        </Text>
                    </Row>
                }
              </Flex>
            </CardPadding>
          </Card>
          <Card>
            <CardPadding>
              <Flex direction="column" gap="3">
                <Text as="h3" size="medium" color="primary" weight="semibold">
                  Inference
                </Text>
                <Text as="p" size="medium" color="secondary">
                  {companyContent.data_use_inference.summary}
                </Text>
                {companyContent.data_use_inference.items.map((item) => (
                  <UsageItem
                    use={item.description}
                    check={item.is_supported}
                  />
                ))}
              </Flex>
            </CardPadding>
          </Card>
        </MainGridContainer>
      </DataUseContainer>
      <ControlsContainer>
        <TitlePadding>
          <Text as="p" size="large" color="primary" weight="medium">
            Controls
          </Text>
        </TitlePadding>
        <MainGridContainer>
          {companyContent.controls.map((controlCategory) => (
            <ControlCard
              key={controlCategory.category}
              title={controlCategory.category}
              onClick={() => navigateToControl(controlCategory.category)}
            >
              {controlCategory.items.map((item) => (
                <ControlCardItem key={item.name} control={item.name} status={item.status}/>
              ))}
            </ControlCard>
          ))}
        </MainGridContainer>
      </ControlsContainer>
    </Flex>
  </Flex>
}

export const HoverCard = styled(Card, {
  cursor: 'pointer',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
  }
})
