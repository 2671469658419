import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { CompanyContent, ControlItem } from '../../api/data-contracts';
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { CheckCircle2, XCircle } from 'lucide-react';

export function getTableStyles() {
  return {
    table: "border-collapse rounded-lg overflow-hidden border border-gray-200",
    headerRow: "bg-gray-50",
    headerCell: "py-4 px-4 text-sm font-semibold text-gray-600 border-b border-gray-200",
    row: "hover:bg-gray-50",
    cell: "py-4 px-4 border-b border-gray-200",
    firstCell: "pl-6 border-l border-gray-200",
    lastCell: "pr-6 border-r border-gray-200",
    icon: "h-5 w-5 inline-block"
  };
}

export default function TrustCenterControls({ companyContent }: { companyContent: CompanyContent }) {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [router.asPath]);

  const filteredControls = companyContent.controls.map((control) => ({
    ...control,
    items: control.items.filter((item) =>
      searchTerm === '' ||
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.summary.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })).filter((controlData) => controlData.items.length > 0);

  const toUrlFriendlyId = (categoryTitle: string) =>
    categoryTitle.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');

  return (
    <div className="flex flex-row px-8">
      <div className="hidden lg:flex flex-col min-w-[300px] pt-8 pr-8 sticky top-0 self-start h-fit">
        <p className="text-lg font-medium text-gray-900 mb-2">Controls</p>
        <nav>
          <ul className="space-y-1">
            {filteredControls.map(({ category }) => {
              const categoryId = toUrlFriendlyId(category);
              return (
                <li key={categoryId}>
                  <Link href={`/?tab=controls#${categoryId}`} className="block py-2 text-sm text-gray-700 hover:text-gray-900">
                    {category}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>

      <div className="flex-1">
        <div className="pt-8 mb-6">
          <Input
            type="search"
            placeholder="Search for controls"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="max-w-md"
          />
        </div>
        {filteredControls.map(({ category, items }) => renderControlsCategory(category, items))}
      </div>
    </div>
  );
}

function renderControlsCategory(title: string, items: ControlItem[]) {
  const titleId = title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
  const styles = getTableStyles();

  return (
    <div id={titleId} className="mb-8 py-4">
      <h1 className="text-2xl font-medium text-gray-900 mb-4">{title}</h1>
      <Table className={styles.table}>
        <TableHeader>
          <TableRow className={styles.headerRow}>
            <TableHead className={`${styles.headerCell} ${styles.firstCell}`}>Control</TableHead>
            <TableHead className={`${styles.headerCell} ${styles.lastCell} w-[100px] text-center`}>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((control) => (
            <TableRow key={control.name} className={styles.row}>
              <TableCell className={`${styles.cell} ${styles.firstCell}`}>
                <div className="space-y-2">
                  <p className="font-medium text-gray-900">{control.name}</p>
                  <p className="text-sm text-gray-600 whitespace-pre-wrap">{control.summary}</p>
                </div>
              </TableCell>
              <TableCell className={`${styles.cell} ${styles.lastCell} text-center`}>
                {control.status ?
                  <CheckCircle2 className={`${styles.icon} text-green-500`} /> :
                  <XCircle className={`${styles.icon} text-gray-400`} />
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}