import Link from 'next/link';
import { ArrowCircleRight } from "@phosphor-icons/react";

interface Props {
  title: string;
  link: string;
}

export const MarketingBanner = ({ title, link }: Props) => {
  return (
    <div className="flex justify-center w-full">
      <Link
        href={link}
        className="inline-flex items-center text-center gap-2 px-3 py-1.5 rounded-full bg-gray-50 border border-gray-300 group hover:shadow-md transition-shadow focus-visible:outline outline-2 outline-offset-2 outline-gray-500"
      >
        <div className="bg-black text-white text-md font-semibold px-3 py-1 rounded-full">
          New
        </div>
        <div className="font-sans text-md font-semibold text-gray-900">
          {title}
        </div>
        <ArrowCircleRight size={24} className="text-gray-700" />
      </Link>
    </div>
  );
};