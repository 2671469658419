import React from 'react';
import Image from 'next/image';
import { MarketingPage } from './MarketingPage';
import { Meta } from '../Meta';
import { MarketingBanner } from "./MarketingBanner";
import CompanyLogosSection from "@/components/marketing/LogoGrid";
import { redirectToLoginOrDashboard } from "@/utils/url";
import Link from "next/link";
import { CTABox } from "@/components/marketing/CTABox";
import Section from "@/components/marketing/Section";

const KEY_LINE = "No more late-night questionnaire scrambles";
const CTA_GET_DEMO = "Get a demo";
const CTA_SIGN_UP = "Sign up for free";

const HeroContent = ({latestPost}) => (
  <div className='flex flex-col items-center w-full max-w-6xl mx-auto bg-white px-4 sm:px-6 lg:px-8 pt-8'>
    <MarketingBanner title={latestPost.title} link={"/blog/" + latestPost.slug}/>
    <h1 className="font-general-sans text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-center mt-8 sm:mt-12 mb-4 sm:mb-6">
      {KEY_LINE}
    </h1>
    <p className="font-general-sans text-lg sm:text-xl md:text-2xl text-center text-gray-600 max-w-3xl mb-6 sm:mb-8">
      Wolfia understands your company, answers questions, and continuously learns to adapt over time — fully autonomous.
    </p>
    <div className="flex flex-col sm:flex-row gap-4 mb-8 sm:mb-12 w-full sm:w-auto">
      <Link
        href={redirectToLoginOrDashboard()}
        className="w-full sm:w-auto px-6 py-3 text-base font-semibold text-white bg-black rounded-full hover:text-white text-center"
      >
        {CTA_SIGN_UP}
      </Link>
      <Link
        href="https://calendly.com/naren-mano/wolfia-demo"
        className="w-full sm:w-auto px-6 py-3 text-base font-semibold text-black bg-white border border-black-800 rounded-full hover:text-black text-center"
      >
        {CTA_GET_DEMO}
      </Link>
    </div>
    <div className="w-full max-w-7xl p-2 sm:p-3 border-2 border-silvermist-100 bg-silvermist-100">
      <div className="border border-silvermist-100 rounded-lg sm:rounded-2xl overflow-hidden">
        <Image
          src="/images/home/wolfia-hero-image.png"
          alt="Wolfia Platform Preview"
          width={1440}
          height={1080}
          layout="responsive"
          objectFit="cover"
        />
      </div>
    </div>
  </div>
);

const HeroContainer = ({latestPost}) => (
  <div className="flex flex-col w-full max-w-screen bg-white">
    <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-10">
      <HeroContent latestPost={latestPost}/>
    </div>
    <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-10">
      <CompanyLogosSection/>
    </div>
  </div>
);

const TestimonialBlock = ({quote, name, title, description, image}) => (
  <div className='flex flex-col justify-between bg-white rounded-xl sm:rounded-2xl border border-gray-200 p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow duration-300'>
    <div className='text-gray-600 mb-4 text-sm sm:text-base'>"{quote}"</div>
    <div className='flex items-center mt-4'>
      <Image
        src={image}
        alt={`Portrait of ${name}`}
        height={40}
        width={40}
        className="rounded-full mr-3 sm:mr-4"
      />
      <div>
        <div className="font-semibold text-gray-900 text-sm sm:text-base">{name}</div>
        <div className="text-xs sm:text-sm text-gray-600">{title}</div>
        {description && <div className="text-xs sm:text-sm text-gray-500 mt-1">{description}</div>}
      </div>
    </div>
  </div>
);

const TestimonialsModule = () => (
  <section className="bg-white px-4 sm:px-6 py-8 sm:py-12 md:py-16 lg:py-20">
    <div className="max-w-7xl mx-auto">
      <div className='text-center mb-8 sm:mb-12'>
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">Trusted by the next generation</h2>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8'>
        <TestimonialBlock
          quote="Nobody enjoys filling out security questionnaires and RFPs. Wolfia has dramatically reduced the repetitive work involved in handling security questionnaires and RFPs. It feels like having superpowers—I can tackle lengthy proposals swiftly without constantly relying on our subject matter experts."
          name="Parker Lewis"
          title="Account Executive · Finley"
          description={null}
          image="/images/home/landingPage_testimonials_portrait_parker.png"
        />
        <TestimonialBlock
          quote="Through Wolfia, I'm able to quickly answer large questionnaires for many different clients. Wolfia has a unique approach to questionnaire automation. They use answers, and other sources of unstructured data, to build a living fact sheet about your security and compliance program."
          name="Rob Picard"
          title="Co-founder & CEO · Observa"
          description="Ex-Security lead at Vanta"
          image="/images/home/landingPage_testimonials_portrait_rob.jpeg"
        />
        <TestimonialBlock
          quote="Wolfia increased the speed of passing security review by 5x. AI compliance has been key for a number of our deals, and Wolfia has helped us turn compliance processes into a strength. We recently did a questionnaire marathon that would have taken 15+ hours without Wolfia!"
          name="David Paffenholz"
          title="Co-founder & CEO · PeopleGPT"
          description={null}
          image="/images/home/landingPage_testimonials_portrait_david.webp"
        />
        <TestimonialBlock
          quote="We've been using Wolfia and AmplifyGRC for our security questionnaires, they save us hours of time, can't imagine life without them."
          name="Angus Parson"
          title="Co-founder & CTO · Solve Intelligence"
          description={null}
          image="/images/home/landingPage_testimonials_portrait_angus.png"
        />
        <TestimonialBlock
          quote="Wolfia and AmplifyGRC have helped expedite security questionnaires and cut times from hours to minutes!"
          name="Gabriel Spencer-Harper"
          title="Co-founder & CEO · Meticulous"
          description={null}
          image="/images/home/landingPage_testimonials_portrait_gabriel.png"
        />
        <TestimonialBlock
          quote="Wolfia helps us turn around security questionnaires in half the time while letting our team focus on higher leverage work."
          name="Will Lawrence"
          title="Co-founder & CEO · Greenlite"
          description={null}
          image="/images/home/landingPage_testimonials_portrait_will.jpeg"
        />
      </div>
    </div>
  </section>
);

const CTABoxSection = () => (
  <Section contentWidth='content-sm' className='text-black px-4 sm:px-6'>
    <CTABox/>
  </Section>
);

export default function LandingPage({latestPost}) {
  return (
    <MarketingPage>
      <Meta title="Wolfia - Accelerate Security Reviews for Modern Companies">
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Wolfia - Accelerate Security Reviews for Modern Companies"/>
        <meta property="og:description" content="Accelerate security reviews for modern companies with our purpose-built security review automation."/>
        <meta property="og:image" content="/images/meta/og-image.png"/>
        <meta property="og:url" content="https://wolfia.com"/>
        <meta property="og:site_name" content="Wolfia"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="630"/>
        <meta property="og:author" content="Wolfia Inc."/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:creator" content="@wolfia_app"/>
        <meta name="twitter:title" content="Wolfia - Accelerate Security Reviews for Modern Companies"/>
        <meta name="twitter:description" content="Accelerate security reviews for modern companies with our purpose-built security review automation."/>
        <meta name="twitter:image" content="https://www.wolfia.com/images/meta/twitter-image.png"/>
      </Meta>
      <HeroContainer latestPost={latestPost}/>
      <TestimonialsModule/>
      <CTABoxSection/>
    </MarketingPage>
  );
}